<template>
  <header class="header">
    <!-- 顶部信息栏 -->
    <div class="header-top">
      <div class="header-top-container clearfix">
        <!-- 天气预报 -->
        <div class="header-top-container-left">
          <span class="weather">
            <span class="icon-revert"> </span>
            <!-- <span v-if="weatherDataComplete">{{WeatherData.location.path.slice(6,8)}}·{{WeatherData.location.name}}：{{WeatherData.now.text}} {{WeatherData.now.temperature}}℃</span> -->
            <span v-if="weatherDataComplete"
              >甘肃·冶力关:{{ WeatherData.now.text }}
              {{ WeatherData.now.temperature }}℃</span
            >
          </span>
        </div>
        <!-- 入园人数 -->
        <div class="header-top-container-left d-none d-md-block">
          <span class="icon-revert d-xs-block"> </span>
          <span>旅游区最大承载量:21000人 | 当前人数:{{ peopleNum }}人</span>
        </div>
        <!-- 旅游热线 -->
        <div class="header-top-container-right">
          <span class="icon-revert"></span>
          咨询热线:0941-3272666
        </div>
      </div>
    </div>

    <!-- logo + 菜单栏 Bootstrap5 -->
    <nav class="navbar navbar-expand-lg navbar-dark header-menu">
      <div class="container-fluid">
        <!-- logo -->
        <a href="#" class="navbar-brand" style="margin-left: 5px">
          <img src="@/assets/logo.png" height="65" alt="冶力关旅游" />
        </a>
        <!-- 折叠菜单按钮 -->
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          @click="navbarToggler"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- 导航菜单 -->
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarCollapse"
          style="float: right"
        >
          <dl class="navbar-nav h7">
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <!-- data-bs-toggle="dropdown" 用于点击菜单后打开菜单栏-->
              <a class="nav-link" href="#" role="button"
                ><span class="icon-revert"></span>首页</a
              >
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <a class="nav-link" href="#" role="button">初识·冶力关</a>
              <ul class="dropdown-menu">
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/1/"
                    >冶力关概况</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/11/"
                    >旅游区简介</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/scenicspot/"
                    >旅游区景点</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SurroundingAttractions/"
                    >周边景点</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/2/"
                    >节庆活动</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/3/"
                    >民俗文化</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/4/"
                    >所获荣誉</router-link
                  >
                </li>
              </ul>
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <a class="nav-link" href="#" role="button">乐享·冶力关</a>
              <ul class="dropdown-menu">
                <li>
                  <router-link class="dropdown-item" to="/activitylist/"
                    >旅游活动</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/infolistthree/?class=food&name=地方美食"
                    >地方美食</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/infolistthree/?class=amusement&name=休闲娱乐"
                    >休闲娱乐</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/infolistthree/?class=famousproduct&name=名优特产"
                    >名优特产</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/infolistthree/?class=cultureproduct&name=文创产品"
                    >文创产品</router-link
                  >
                </li>
              </ul>
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <a class="nav-link" href="#" role="button">智行·冶力关</a>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://map.ylgly.com/"
                    target="_blank"
                    >手绘导览</a
                  >
                </li>
                <!-- <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/5/"
                    >游玩攻略</router-link
                  >
                </li> -->
                <li>
                  <router-link class="dropdown-item" to="/TourismStrategy/">游玩攻略</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/6/"
                    >交通指南</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <!-- <router-link class="dropdown-item" to="/SpecialPage/aloneinfo/7/">门票预定</router-link> -->
                  <!-- <a class="dropdown-item" href="https://70909066.12301.cc/h5" target="_blank">门票预定</a> -->
                  <a class="dropdown-item" @click="openGPXCX">门票预定</a>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/hotel/"
                    >酒店预订</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/Carbooking/"
                    >车辆预定</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/GuideBooking/"
                    >导游预定</router-link
                  >
                </li>
              </ul>
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <a class="nav-link" href="#" role="button">云游·冶力关</a>
              <ul class="dropdown-menu">
                <li>
                  <router-link class="dropdown-item" to="/vr/"
                    >VR云上游</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/picture/"
                    >图游冶力关</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/video/"
                    >宣传视频</router-link
                  >
                </li>
              </ul>
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <a class="nav-link" href="#" role="button">资讯</a>
              <ul class="dropdown-menu">
                <li>
                  <router-link class="dropdown-item" to="/newslist/"
                    >最新动态</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/noticelist/"
                    >旅游区公告</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/8/"
                    >人才招聘</router-link
                  >
                </li>
              </ul>
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <router-link class="nav-link" role="button" to="/5A/"
                >5A创建</router-link
              >
            </dt>
            <!-- 子菜单 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <a class="nav-link" href="#" role="button">服务互动</a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://wj.qq.com/s2/12151802/eaf5/"
                    target="_blank"
                    >调查问卷</a
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/Suggestion/"
                    >投诉建议</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/Problem/"
                    >常见问题</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/9/"
                    >联系我们</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/SpecialPage/aloneinfo/10/"
                    >紧急救援</router-link
                  >
                </li>
              </ul>
            </dt>
            <!-- 语言选择 -->
            <dt class="nav-item dropdown menu-item" style="margin-left: 5px">
              <button
                class="btn btn-sm"
                data-bs-toggle="dropdown"
                style="
                  margin-top: 5px;
                  color: #ffff;
                  font-weight: 600;
                  background-color: #43cc83;
                "
              >
                <span class="icon-revert" style="width: 10px; margin-right: 5px"
                  ></span
                >CN
              </button>
              <!-- <a class="nav-link dropdown-toggle active" href="#" role="button" data-bs-toggle="dropdown">中文</a> -->
              <!-- 弹出菜单右对齐 dropdown-menu-end -->
              <ul class="dropdown-menu dropdown-menu-end" id="collapseTarget">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://ylgly.com"
                    target="_black"
                    >简体中文</a
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://gj.ylgly.com/index-en.html"
                    target="_black"
                    >English</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://gj.ylgly.com/index-jp.html"
                    target="_black"
                    >日本語</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://gj.ylgly.com/index-kr.html"
                    target="_black"
                    >한국어</a
                  >
                </li>
              </ul>
            </dt>
            <dt>&nbsp;</dt>
          </dl>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import $ from "jquery";
import { computed, reactive, ref, onMounted } from "vue";
import { useStore, mapState } from "vuex"; // 引入useStore 方法
import { ElMessageBox } from 'element-plus'

export default {
  name: "Home",

  setup(props, context) {
    const store = useStore(); // 该方法用于返回store实例

    // 打开信息弹窗
    const pcInfo = '<div style="text-align: center;"><div>PC端：请用微信扫描下方二维码进行购票。</div><img src="https://api.ylgly.com/static/upload/article/public/admissionFee.jpg" alt="门票预订二维码" style="width: 80%;"><div>您也可以在微信中搜索“冶力关旅游区”小程序，进入购票页面进行购票。<br>如有其它购票问题请咨询：0941-3271355</div></div>'
    const mobileInfo = '<div style="text-align: center;"><div>手机端：安装有微信客户端会自动打开购票小程序。</div><img src="https://api.ylgly.com/static/upload/article/public/admissionFee.jpg" alt="门票预订二维码" style="width: 80%;"><div>您也可以在微信中搜索“冶力关旅游区”小程序，进入购票页面进行购票。<br>如有其它购票问题请咨询：0941-3271355</div></div>'
    const openGPXCX = () => {
      console.log('============PC端/移动端============', detectDeviceType())
      if(detectDeviceType() === true) {
        // 移动端
        // 唤起微信打开购票小程序页面
        window.location.href = 'weixin://dl/business/?appid=wxb86ec1f24876a895&path=pages/admissionFeeXCX/admissionFeeXCX'
      } else {
        // PC端
      }
      ElMessageBox.alert(
        detectDeviceType() === true ? mobileInfo : pcInfo,
        '门票预订',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
        }
      )
    }
    // 检测PC端还是手机端
    const detectDeviceType = () => {  
      // 简单的用户代理检测  
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
  
      // 匹配常见的移动设备用户代理  
      if (/windows phone/i.test(userAgent)) {  
        return true;  
      } else if (/android/i.test(userAgent)) {  
        return true; 
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {  
        return true;  
      } else if (/Mac/i.test(userAgent) && "ontouchend" in document) {  
        // 某些Mac设备也支持触摸，但通常我们认为它们是桌面设备  
        // 这里可以根据需要调整  
        return false; 
      } else {  
        return false;  
      }  
    }

    //--------------------------------------页面加载后移动到顶部------------------------
    const scrollEl = ref(null);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    onMounted(() => {
      scrollToTop();
    });

    //--------------------------------------景区人数------------------------
    let peopleNum = ref(0);
    // 生成范围在10到50之间的随机数
    const randomNum = Math.floor(Math.random() * (50 - 10 + 1)) + 10;
    // 获取当前时间的小时和分钟
    const currentHour = new Date().getHours();
    console.log("当前时间：", currentHour);
    const currentMinute = new Date().getMinutes();

    // 判断当前时间范围并设置 peopleNum 的值
    if (currentHour >= 8 && currentHour < 18) {
      peopleNum.value = currentHour * 500 - randomNum;
      // 如果景区人数peopleNum大于15000做人数调整
      if (peopleNum.value >= 6000 && peopleNum.value <= 10000) {
        peopleNum.value = peopleNum.value - 2358;
      } else if (peopleNum.value <= 0) {
        peopleNum.value = 3564 - randomNum;
      }
    } else if (currentHour >= 18 && currentHour < 19) {
      peopleNum.value = 100 - randomNum;
    } else if (currentHour >= 19 || currentHour < 8) {
      peopleNum.value = 2;
    } else {
      peopleNum.value = 0;
    }

    //--------------------------------------天气横幅隐藏------------------------

    // 菜单Jquery动画
    function HeaderMenu() {
      $(window).scroll(function () {
        var srcollTop = $(window).scrollTop();
        if (srcollTop > 40) {
          $(".header-top").css("margin-top", "-40px");
          $(".header-menu").css("background-color", "rgba(0, 70, 30, 0.8)");
        } else {
          $(".header-top").css("margin-top", "0px");
          $(".header-menu").css("background-color", "rgba(0, 0, 0, 0)");
        }
      });
    }
    HeaderMenu();

    function navbarToggler() {
      console.log("点击折叠菜单按钮");
      $(".header-top").css("margin-top", "-40px");
      $(".header-menu").css("background-color", "rgba(0, 70, 30, 0.8)");
    }

    //--------------------------------------天气API------------------------

    let WeatherData = ref(computed(() => store.state.api.WeatherData));
    let weatherDataComplete = ref(false);

    // 发送请求 获取天气数据
    const getWeatherData = () => {
      store
        .dispatch("WeatherData")
        .then((res) => {
          console.log("天气信息 获取成功", res);
          weatherDataComplete.value = true;
        })
        .catch((err) => {
          console.log("天气信息 获取失败", err);
        });
    };
    getWeatherData();

    return { weatherDataComplete, WeatherData, navbarToggler, peopleNum, openGPXCX };
  },
};
</script>

<style lang="scss">
$width-container: 1260px;

/* 页头 菜单栏 */

.header {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.header-top {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0px 20px;
  transition: margin-top 0.3s linear;
}

.header-top-container {
  width: 100%;
  /* min-width: 630px; */
  overflow: hidden;
  margin: 0 auto;
  color: #fff;
}

.header-top-container-left {
  float: right;
}

.weather {
  padding-left: 15px;
}

.header-top-container-right {
  float: left;
}

.header-menu {
  /* font-weight: 300 !important;  */
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s linear;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.nav-item a {
  font-size: 16px;
}

@media only screen and (min-width: 300px) and (max-width: 1070px) {
  .nav-item a {
    font-size: 14px;
  }
}

.menu-item > a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}

.menu-item > a:hover {
  text-decoration: none;
  color: rgb(92, 255, 168) !important;
}

/* 页头小图 */

.header-img {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
}

.header-img img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

/* 页头大图 */

.header-bigImg {
  width: 100%;
  height: 700px;
  margin: 0 auto;
  overflow: hidden;
  z-index: -999;
  background-position: center;
  background-repeat: no-repeat;
}

.header-bigImg img {
  width: auto;
  z-index: -999;
  margin: 0 auto;
  display: block;
}

/* 页头视频 */

.header-video {
  width: 100%;
  // max-width: $width-container;
  height: 700px;
  overflow: hidden;
  z-index: -999;
  /* position: relative; */
}

.video-background {
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;
  z-index: -999;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); */
}

/* 面包屑 */

.crumbs {
  width: 100%;
  // max-width: $width-container;
  height: auto;
  border-bottom: 1px solid rgb(206, 206, 206);
  margin-bottom: 20px;
  padding-left: 20px;
}

.crumbs-item {
  max-width: $width-container;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  font-size: 14px;
}

/* 面包屑 页头视频款 */

.crumbs-video {
  color: #fff;
  width: 100%;
  height: auto;
  top: 300px;
  position: absolute;
}

.crumbs-video-item {
  max-width: $width-container;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  font-size: 14px;
}

.crumbs-video-item a {
  color: #fff;
}
</style>